<template>
    <defaultSec :title="'企微日报'">
        <keep-alive>
            <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
                <el-form-item label="请选择日期">
                    <el-date-picker
                            v-model="pickDate"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="企微">
                    <el-select v-model="formParam.qyWeixinUserid"  filterable clearable
                               placeholder="请选择企微">
                        <el-option v-for="item in qiweiUserList"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="initList">查询</el-button>
                </el-form-item>
            </el-form>
        </keep-alive>
        <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
            <el-table :data="dataList" border size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column label="企微" prop="DISTRIBUTOR_NAME" width="80"></el-table-column>
                <el-table-column label="日期" prop="CREATE_TIME" width="150"></el-table-column>
                <el-table-column label="GMV" prop="GMV" ></el-table-column>
            </el-table>
        </tablePagination>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                formParam: {
                    pageSize: 20,
                    currentPage: 1,
                    qyWeixinUserid:this.$route.params.qyWeixinUserid||'',
                    start: '',
                    end:''
                },
                dataList: [],
                pickDate:[],
                typeList:[],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                qiweiUserList:[],
                showDialog: false,
                total: 0
            }
        },
        components: {
            defaultSec, tablePagination
        },
        mounted(){
            this.initList()
            this.initQwUserList()
        },
        methods: {
            initQwUserList(){
                this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
                    console.log(res)
                    this.qiweiUserList = res
                })
            },
            initList() {
                this.dataList = []
                if(this.pickDate!=''&&this.pickDate!=null){
                    this.formParam.start=this.pickDate[0];
                    this.formParam.end=this.pickDate[1];
                }else {
                    this.formParam.start='';
                    this.formParam.end='';
                }
                this.$apiGet('lechun-cms/echelon/getDaily', this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.dataList = res.list
                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            toDetail(experienceId,operateType){
                console.log('experienceId='+experienceId+",operateType="+operateType)
                this.$router.push({name: 'experienceDetail', params: {experienceId: experienceId,operateType:operateType}})
            }
        }
    }
</script>

<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>